<template>
  <a-modal :title="ModalTitle" v-model="visible"
    cancelText="取消" okText="确定" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
        :columns="columns" :dataSource="list">
        <template slot="user" slot-scope="text,record">
          <a :href="`/work/profile/${record.student_id}`">{{ text }}</a>
        </template>
        <template slot="action" slot-scope="text,record">
          <a :href="`/work/profile/${record.student_id}`">业务</a>
        </template>
      </a-table>
    </div>
    <!-- <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div> -->
  </a-modal>
</template>

<script>
const columns = [ 
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: 'name', scopedSlots: { customRender: 'user' } },
  { title: '性别', dataIndex: 'student_gender', key: '1' },
  { title: '课程状态', dataIndex: 'book_status', key: '5' },
]
					
export default {
  name: 'ListModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      ModalTitle: '上课学员列表',
      visible: false,
    }
  },

  created() {
    this.visible = true
    this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.getList()
    },
    async getList() {
      let res = await this.$store.dispatch('courseScheduleDetailAction', { data: { course_class_id: this.item.id } })
      this.list = res.items
      this.pageParams = res._meta
    }
  }
}
</script>