<template>
  <a-modal :title="ModalTitle" v-model="visible"
    cancelText="取消" okText="保存" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="课程名称">
        <a-select v-model="formData.course_id" placeholder="请选择">
          <a-select-option v-for="(d, index) of courses" :key="index" :value="d['course_id']">{{ d['filter_name'] }}</a-select-option>
        </a-select>
        <a-checkbox v-model="formData.is_demo">Demo体验课</a-checkbox>
      </a-form-item>
      <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="课表">
        <a-table :columns="columns" :dataSource="data" :bordered='false' size="small" :pagination="false">
          <template slot="room" slot-scope="text, record">
            <a-select v-model="record.room_id" placeholder="请选择">
              <a-select-option v-for="(d, index) of rooms" :key="index" :value="d['room_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
          </template>
          <template slot="teacher" slot-scope="text, record">
            <a-select v-model="record.teacher_id" placeholder="请选择">
              <a-select-option v-for="(d, index) of teachers" :key="index" :value="d['teacher_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
          </template>
          <template slot="classDate" slot-scope="text, record">
            <a-date-picker :allowClear="false" v-model="record.class_date" placeholder="请选择"/>
          </template>
          <template slot="classStart" slot-scope="text, record">
            <a-time-picker :allowClear="false" format="HH:mm" v-model="record.class_start" placeholder="请选择" />
          </template>
          <template slot="action" slot-scope="text,record, index">
            <a @click="deleteItem(index)">删除</a>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            <div style="text-align: center"><a-button @click="addItem" icon="plus">添加</a-button></div>
          </template>
        </a-table>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const columns = [
  { title: '上课教室', width: '20%', dataIndex: 'room_id', key: '1', scopedSlots: { customRender: 'room' }, },
  { title: '授课老师', width: '20%', dataIndex: 'teacher_id', key: '2', scopedSlots: { customRender: 'teacher' }, },
  { title: '上课日期', dataIndex: 'class_date', key: '3', scopedSlots: { customRender: 'classDate' }, },
  { title: '开始时间', dataIndex: 'class_start', key: '4', scopedSlots: { customRender: 'classStart' }, },
  { title: '操作', width: 46, key: 'action', scopedSlots: { customRender: 'action' },},
];
import moment from 'moment'
export default {
  name: 'AddModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '添加课程',
      form: this.$form.createForm(this),
      visible: false,
      courses: [],
      rooms: [],
      teachers: [],
      formData: {
        course_id: ''
      },
      data: [],
      columns,
    }
  },

  async created() {
    this.visible = true
    this.getCourses()
    await this.getRoom()
    await this.getTeacher()
    this.addItem()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getCourses() {
      let res = await this.$store.dispatch('searchCourseAction', {})
      this.courses = res.data
      this.formData.course_id = this.courses[0].course_id
      this.$forceUpdate()
    },
    async getRoom() {
      let res = await this.$store.dispatch('searchStudioRoomAction', {})
      this.rooms = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    addItem() {
      let key = 1
      if (this.data.length > 0) {
        key = this.data[this.data.length-1].key + 1
      }
      this.data.push({
        key,
        room_id: this.rooms[0].room_id,
        teacher_id: this.teachers[0].teacher_id,
        class_date: moment(this.item.day),
        class_start: moment('08:30', 'HH:mm')
      })
    },
    deleteItem(index) {
      this.data.splice(index, 1)
    },
    async handleOk() {
      this.confirmLoading=true;
      if (this.data.length <= 0) {
        return 
      }
      let course_schedule = []
      for (let d of this.data) {
        course_schedule.push({
          room_id: d.room_id,
          teacher_id: d.teacher_id,
          class_date: moment(d.class_date).format('YYYY-MM-DD'),
          class_start: moment(d.class_start).format('HH:mm')
        })
      }
      let data = {
        studio_id: this.item.studio_id,
        course_id: this.formData.course_id,
        is_demo: this.formData.is_demo? 1: 0,
        course_schedule
      }
      let res = await this.$store.dispatch('courseScheduleAddScheduleAction', { data })
      this.parent.hideAddModal(1)
    },
    handleCancel() {
      this.parent.hideAddModal(0)
    }
  }
}
</script>