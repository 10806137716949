<template>
  <div>
    <a-breadcrumb class="breadcrumb" v-if="!isUse">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>课表管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools" style="border-bottom: 1px solid #f1f1f1; margin-bottom: 15px;padding-left:15px">
        <div class="buttons">
        </div>
        <div class="search">
          <!-- <a-form layout='inline'>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>上课校区</span>
                </template>
                <a-select v-model="searchParams.search.studio_id" placeholder="请选择校区" @search="e=>handleSearch(e,1)" showSearch :filterOption="filterOption" style="width: 160px" @change="getStudioDetail">
                  <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>授课老师</span>
                </template>
                <a-select allowClear v-model="searchParams.search.teacher_id" showSearch :filterOption="filterOption" placeholder="请选择授课老师" style="width: 160px">
                  <a-select-option v-for="(d, index) of teachers" :key="index" :value="d['teacher_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>上课教室</span>
                </template>
                <a-select allowClear v-model="searchParams.search.room_id" showSearch  :filterOption="filterOption" placeholder="请选择上课教室" style="width: 160px">
                  <a-select-option v-for="(d, index) of rooms" :key="index" :value="d['room_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item v-if="canExcelexport">
              <a-button type="primary" @click="toExport">导出</a-button>
            </a-form-item>
          </a-form> -->
        </div>
      </div>
      <div class="common">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div class="c-main">
          <full-calendar ref="calendar" :events="events" :config="config"></full-calendar>
          <!-- <vue-event-calendar ref='vueEventCalendar' :events="list" title="课程列表" @day-changed="handleDayChanged" @month-changed="handleMonthChanged">
              <template slot-scope="props">
                  <div v-for="(event, index) in props.showEvents" :key='index' class="event-item" @click="handleEvent(event)">
                  <h3 class="scheduleModle-event-item-title">{{index + 1}}.{{event.title}}</h3>
                  <span class="scheduleModle-event-item-date">{{event.date}}</span>
                  </div>
              </template>
          </vue-event-calendar> -->
        </div>
        <!-- <div class="c-side schedule" style="width: 450px; border-left: 1px solid #f1f1f1;border-right: none;margin-left: 15px;">
          <div class="table">
            <vue-element-loading :active="loadingF" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="book_id"
              :columns="columns" :dataSource="studentList">
              <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
              </template>
              <template slot="user" slot-scope="text, record">
                <LUser :data="record"/>
              </template>
              <template slot="book_status" slot-scope="text,record,index">
                  <a-radio-group :value="record.book_status" @change="handleRadio(index,record)" button-style="solid" size="small">
                    <a-radio :value="1" disabled>
                      待上课
                    </a-radio>
                    <a-radio :value="2">
                      已到课
                    </a-radio>
                    <a-radio :value="0">
                      已请假
                    </a-radio>
                  </a-radio-group>
              </template>
            </a-table>
          </div>
          <div class="page">
            <a-pagination
              :pageSizeOptions="pageSizeOptions"
              :total="pageParams.totalCount"
              showSizeChanger
              :pageSize="pageParams.perPage"
              v-model="current"
              @change="changePage"
              @showSizeChange="onShowSizeChange"
              :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                  <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                  <span v-if="props.value==='100'">100条/页</span>
                </template>
              </a-pagination>
          </div>
        </div> -->
      </div>
    </div>
    <listModal v-if="listVisible" :item="modalData"/>
    <addModal v-if="addVisible" :item="modalData"/>
  </div>
</template>
<style lang="scss" scoped>
.schedule {
  .panel {
    padding-left: 10px;
    margin-bottom: 20px;
    .title {
      border-bottom: 1px solid #f1f1f1;
      font-size: 16px;
      padding-bottom: 10px;
    }
    .cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        background: #f1f1f1;
      }
      .photo {
        margin: 20px 0 10px;
        width: 88px;
        height: 88px;
        border-radius: 50%;
        overflow: hidden;
      }
      .qrcode {
        box-sizing: border-box;
        width: 300px;
        height: 300px;
        padding: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
    .event-item{
        position: relative;
        display: flex;
        align-items: center;
        .scheduleModle-event-item-title{
            height: 40px;
            line-height: 40px;
            color: #323232;
            font-size: 16px;
        }
        .scheduleModle-event-item-date{
            position: absolute;
            right: 20px;
            color: #9b9b9b;
        }
    }
    .cal-wrapper{
      padding:0 50px !important;
    }
</style>

<script>
const columns = [
  { title: '#',width:'40px', scopedSlots: { customRender: 'index' } , key: 'index'},
  { title: '学员名称', width:'200px', dataIndex: 'student_name', key: 'student_name' ,scopedSlots: { customRender: 'user' }},
  { title: '上课状态', dataIndex: 'book_status', key: '2' , scopedSlots: { customRender: 'book_status' }},
]


import moment from 'moment'
import listModal from './listModal'
import addModal from './addModal'
import authority from '@/common/mixins/authority'

export default {
  provide() {
    return {
      parent: this
    }
  },

  props: {
    isUse: Number
  },
  mixins: [ authority ],
  components: {
    listModal,
    addModal,
  },
  data() {
      return {
        columns,
        loading:false,
        loadingF:false,
        curStudio: {},
        studios: [],
        teachers: [],
        rooms: [],
        events:[],
        config: {
          header: {
            left: 'prev,next',
            center: 'title',
            right: 'month,basicWeek'
          },
          buttonText: { today: "本周", month: "月", week: "周", day: "日" },
          locale: "zh-cn",
          editable: false, //是否允许修改事件
          selectable: false,
          allDaySlot: false, //是否显示allDay
          defaultView: "basicWeek", //显示默认视图
          eventClick: this.eventClick, //点击事件
          dayClick: this.dayClick, //点击日程表上面某一天
          viewRender: this.viewRenderAction,
        },
        modalData: {},
        listVisible: false,
        addVisible: false,
        qrcode: '',
        authType:['education','schedule'],
        list:[],
        studentList:[],
        pageParams: {
          currentPage: 1,
          pageCount: 50,
          perPage: 10,
          totalCount: 0
        },
        searchParams: {
          "page": 1,
          "per-page": 10,
          "search": {
            start_date:moment().startOf('week').format('YYYY-MM-DD'),
            end_date:moment().endOf('week').format('YYYY-MM-DD'),
          },
          "sort": ''
        },
        current: 1,
        isFirst:0,
        pageSizeOptions: ['10', '20', '30', '40', '100'],
      }
  },
  async mounted() {
    // await this.getStudio()
    // this.getTeacher()
    // this.getRoom()
    // this.getWeekData()
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
     // 点击事件
     eventClick (event, jsEvent, pos) {
       console.log('eventClick', event, jsEvent, pos)
       this.showListModal(event)
     },
     // 点击当天
     dayClick (day, jsEvent) {
        console.log('dayClick', day, jsEvent)
        this.showAddModal({
          studio_id: this.searchParams.search.studio_id,
          day
        })
     },

    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
      this.searchParams.search.studio_id = this.studios[0].studio_id
      this.getStudioDetail(this.searchParams.search.studio_id)
    },
    async getStudioDetail(studio_id) {
      let res = await this.$store.dispatch('studioDetailAction', {data: { studio_id }})
      console.log(res)
      this.curStudio = res.data
      
      let res2 = await this.$store.dispatch('toolsQrcodeAction', {data: { txt: this.curStudio.access_url }})
      this.qrcode = res2.data.qrcode
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getRoom() {
      let res = await this.$store.dispatch('searchStudioRoomAction', {})
      this.rooms = res.data
    },
    handleSearch(e,num){
      console.log(e,num)
    },
    getDayData() {
      this.searchParams.search.start_date = moment().format('YYYY-MM-DD')
      this.searchParams.search.end_date = moment().format('YYYY-MM-DD')
      this.getList()
    },
    getWeekData() {
      this.searchParams.search.start_date = moment().isoWeekday(1).format('YYYY-MM-DD')
      this.searchParams.search.end_date = moment().isoWeekday(7).format('YYYY-MM-DD')
      this.getList()
    },
    getMonthData() {
      this.searchParams.search.start_date = moment().month(moment().month()).startOf('month').format('YYYY-MM-DD')
      this.searchParams.search.end_date = moment().month(moment().month()).endOf('month').format('YYYY-MM-DD')
      this.getList()
    },
    async searchList() {
      this.getList()
    },
    viewRenderAction(view, element) {
      console.log('27272727772727272')
      this.searchParams.search.start_date = moment(view.start).format('YYYY-MM-DD')
      this.searchParams.search.end_date = moment(view.end).format('YYYY-MM-DD')
      this.getList()
    },

    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        
      }
      this.listVisible = false
    },

    showAddModal(item) {
      this.modalData = item
      this.addVisible = true
    },
    hideAddModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.addVisible = false
    },
    async toExport(){
      let that = this
      let name = '';
      this.studios.forEach((item)=>{
        if(item.studio_id == that.searchParams.search.studio_id){
          name = item.filter_name
        }
      })
      let res = await this.$store.dispatch('courseScheduleExportAction',this.searchParams)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `${name}课表.xls`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
    },


    async getList(){
        if(!this.canIndex){
            this.$message.warning('您暂无权限!!')
            return false
        }
        this.loading = true
        let res = await this.$store.dispatch('scheduleCourseListAction', this.searchParams)
        let events = []
        res.data.forEach(item => {
            let obj = {
              id:item.course_id,
              title:item.title,
              start:moment(item.date).format('YYYY/MM/DD HH:mm'),
              end:moment(item.date).format('YYYY/MM/DD HH:mm'),
              details:item
            }
            events.push(item)
        })
        this.events = events
        this.loading = false
    },
    async getStudentList() {
      this.loadingF = true
      let res = await this.$store.dispatch('courseScheduleDetailAction', { data: this.searchParams })
      this.pageParams = res._meta
      this.studentList = res.items
      this.loadingF = false
    },
    handleDayChanged(a){
        console.log(a)
    },
    handleMonthChanged(val){
        var month = val.split('月')[0].replace(/年/,'-')
        this.searchParams.search.start_date = moment(moment(month)).startOf('month').format('YYYY-MM-DD')
        this.searchParams.search.end_date = moment(moment(month)).endOf('month').format('YYYY-MM-DD')
        this.getList()
    },
    handleEvent(val){
      this.searchParams.course_class_id = val.course_class_id
      this.getStudentList()
    },
    searchList() {
      this.searchParams.page = 1
      this.getStudentList()
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getStudentList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.getStudentList()
    },
  },
  watch: {
    // list(newValue, oldValue) {
    //   console.log(this.$EventCalendar.toDate)
    //   this.$EventCalendar.toDate('2020/6/14')
    // }
  },
}
</script>
